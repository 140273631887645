header.header {
  padding: 20px;
  background-color: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

header.header nav.nav {
  max-width: 1024px;
  margin: auto;
  display: flex;
  align-items: center;
}

header.header nav.nav .toggle {
  display: none;
}

header.header nav.nav .logo-wrapper,
header.header nav.nav .buttons-wrapper {
  flex: 0 1 auto;
}

header.header nav.nav .links-wrapper {
  flex: 1 1 auto;
  padding: 0 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
header.header nav.nav .links-wrapper a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #969696;
  display: inline-block;
  padding: 0 5px;
}
header.header nav.nav .links-wrapper a:hover, 
header.header nav.nav .links-wrapper a.active {
  color: #fff;
}
header.header nav.nav .buttons-wrapper a {
  background: #212121;
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #FFFFFF;
  display: inline-block;
  padding: 6px 30px;
  margin-left: 20px;
}
header.header nav.nav .buttons-wrapper a.book-a-demo-btn {
  background: #539B2E;
}

@media (max-width: 1119px) {
  header.header nav.nav {
    display: block;
  }
  header.header nav.nav .buttons-wrapper,
  header.header nav.nav .links-wrapper {
    display: none;
  }
  header.header nav.nav .toggle {
    display: block;
  }
  header.header nav.nav .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header.header nav.nav.show .links-wrapper {
    display: block;
    padding: 20px 0;
  }
  header.header nav.nav.show .links-wrapper a {
    display: block;
    padding: 10px 0;
  }
  header.header nav.nav.show .buttons-wrapper {
    display: block;
    padding: 20px 0;
  }
  header.header nav.nav.show .buttons-wrapper a {
    margin-left: 0;
    margin-right: 20px;
  }
}

.cookie-note {
  padding: 20px;
  background: #070707;
  display: flex;
  align-items: center;
  position: fixed;
  margin: 0;
  width: 100vw;
  bottom: 0;
  box-sizing: border-box;
  z-index: 999999;
}
.cookie-note-details {
  flex: 1 1 auto;
}
.cookie-note-details h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 5px;
  color: #fff;
}
.cookie-note-details p {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #fff;
}
.cookie-note-details a {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-decoration: underline;
}
.cookie-note button {
  margin: 10px;
  min-width: 175px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  padding: 16px;
  background: #539B2E;
  color: #fff;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .cookie-note {
    display: block;
  }
  .cookie-note button {
    display: block;
    margin: 20px 0 0;
  }
}