footer.footer {
  padding: 50px 20px;
  background-color: #212121;
}
footer.footer img {
  max-width: 100%;
}
footer.footer h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #FFFFFF;
}
footer.footer a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #FFFFFF;
}
footer.footer .footer-inner {
  max-width: 1024px;
  margin: auto;
}
footer.footer .footer-inner .bottom-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  footer.footer .footer-inner .bottom-part {
    display: block;
  }
  footer.footer .footer-inner .bottom-part .company {
    margin-bottom: 20px;
  }
}