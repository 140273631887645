main.home .banner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  background-image: url('https://d365pq86x330zn.cloudfront.net/4e8ee796-6596-46x1-7647-567791186p57.png');
  background-position: center;
  background-size: cover;
}
main.home .banner .banner-inner {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
}
main.home .banner .banner-inner .details {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 80px 20px 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
main.home .banner .banner-inner .details h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.2;
  max-width: 600px;
  margin: 70px auto 30px 0;
  color: #69B940;
}
main.home .banner .banner-inner .details span {
  color: #fff;
}
main.home .banner .banner-inner .details span > div {
  display: inline!important;
}
main.home .banner .banner-inner .details p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  max-width: 600px;
  margin: 0 auto 30px 0;
  color: #FFFFFF;
}
main.home .banner .banner-inner .details .btn {
  display: inline-block;
  border: none;
  outline: none;
  padding: 15px 45px;
  background: #539B2E;
  border-radius: 53px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  max-width: max-content;
  color: #FFFFFF;
}
main.home .banner .banner-inner .details .img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
main.home .banner .banner-inner .details .img-wrapper .img {
  display: inline-block;
  height: fit-content;
  width: fit-content;
  position: relative;
}
main.home .banner .banner-inner .details .img-wrapper .img .video {
  position: absolute;
  top: 21%;
  left: 7%;
  width: 84%;
  height: 56%;
  border-radius: 1%;
}
main.home .banner .banner-inner .details img {
  max-width: 100%;
}
main.home .banner .banner-bottom {
  padding: 20px 0;
  background-color: rgba(0, 0, 0, .3);
}
main.home .banner .banner-bottom .banner-bottom-inner {
  max-width: 1024px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
main.home .banner .banner-bottom .banner-bottom-inner .logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

main.home .how-it-works {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  background-image: url('https://d365pq86x330zn.cloudfront.net/ce84c6ee-7e54-43RR-5830-6516468Rc14e.png');
  background-position: center;
  background-size: cover;
}
main.home .how-it-works .how-it-works-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 50px 20px;
  box-sizing: border-box;
}
main.home .how-it-works h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  max-width: 900px;
  margin: 30px auto 40px 0;
  color: #FFFFFF;
}
main.home .how-it-works p {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  max-width: 900px;
  margin: 0 auto 60px 0;
  color: #FFFFFF;
}
main.home .how-it-works .how-it-works-inner .list {
  display: flex;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto 50px 0;
}
main.home .how-it-works .how-it-works-inner .list p {
  font-weight: 500;
  margin: 0;
  padding: 0 20px;
}

main.home .carbon-zero-dash {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  background-image: url('https://d365pq86x330zn.cloudfront.net/1159p64x-89x4-4e11-948x-1e0599e44119.png');
  background-position: bottom;
  background-size: cover;
}
main.home .carbon-zero-dash .carbon-zero-dash-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 50px 20px;
  box-sizing: border-box;
}
main.home .carbon-zero-dash .carbon-zero-dash-inner img {
  max-width: 100%;
}

main.home .whop {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #262F34;
}
main.home .whop .whop-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 50px 20px;
  box-sizing: border-box;
}
main.home .whop .whop-inner h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  margin: 30px auto 40px;
  text-align: center;
  color: #FFFFFF;
}
main.home .whop .whop-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  margin: 20px auto;
  color: #FFFFFF;
}
main.home .whop .whop-inner em {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;
  color: #FFFFFF;
}
main.home .whop .whop-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  max-width: 600px;
  margin: 30px auto 40px 0;
  color: #FFFFFF;
}
main.home .whop .whop-inner img {
  max-width: 100%;
}
main.home .whop .whop-inner .airplane {
  text-align: right;
  margin-bottom: 20px;
}
main.home .whop .whop-inner .nature {
  display: grid;
  grid-auto-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-flow: column;
  grid-gap: 30px;
}
main.home .whop .whop-inner .nature .nature-inner {
  display: flex;
  align-items: center;
}

main.home .footplan {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  background-image: url('https://d365pq86x330zn.cloudfront.net/5598861x-96e6-459e-9855-580851450594.png');
  background-position: center;
  background-size: cover;
}
main.home .footplan .footplan-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 50px 20px;
  box-sizing: border-box;
}
main.home .footplan .footplan-inner h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  margin: 30px auto 40px;
  max-width: 600px;
  text-align: center;
  color: #FFFFFF;
}
main.home .footplan .footplan-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: #FFFFFF;
}
main.home .footplan .footplan-inner p strong {
  font-weight: 700;
  font-size: 84px;
  display: block;
  margin-bottom: 10px;
}
main.home .footplan .footplan-inner .plans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

main.home .ai-suggest {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #212121;
}
main.home .ai-suggest .ai-suggest-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 50px 20px;
  box-sizing: border-box;
}
main.home .ai-suggest .ai-suggest-inner h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  margin: 30px auto 40px;
  color: #FFFFFF;
}
main.home .ai-suggest .ai-suggest-inner .suggests {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
main.home .ai-suggest .ai-suggest-inner .suggests img {
  max-width: 100%;
}
main.home .ai-suggest .ai-suggest-inner .suggests .suggest {
  background: #FCFCFC;
  border-radius: 10px;
  overflow: hidden;
}
main.home .ai-suggest .ai-suggest-inner .suggests .suggest .details {
  padding: 18px 20px 25px 20px;
}
main.home .ai-suggest .ai-suggest-inner .suggests .suggest .details h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: #2A3E2B;
  margin: 0 0 10px 0;
}
main.home .ai-suggest .ai-suggest-inner .suggests .suggest .details p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #999999;
  margin: 0;
}
main.home .ai-suggest .ai-suggest-inner .cost {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-gap: 20px;
}
main.home .ai-suggest .ai-suggest-inner .cost-inner {
  display: flex;
  align-items: center;
}
main.home .ai-suggest .ai-suggest-inner .cost-inner h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 1.2;
  align-items: center;
  color: #FFFFFF;
  padding: 0 20px;
}
main.home .ai-suggest .ai-suggest-inner label {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.2;
  color: #FFFFFF;
  margin-right: auto;
  display: block;
}
main.home .ai-suggest .ai-suggest-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #FFFFFF;
  margin-right: auto;
  max-width: 720px;
}
main.home .ai-suggest .ai-suggest-inner .btn {
  outline: none;
  border: none;
  background: #539B2E;
  border-radius: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  color: #FFFFFF;
  margin: auto;
  padding: 28px;
  width: 100%;
  max-width: 300px;
  display: block;
  text-align: center;
}

main.home .offset {
  background-color: #000;
  background-image: url('https://d365pq86x330zn.cloudfront.net/6x366312-1627-42e6-2166-2p622x567626.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
main.home .offset .offset-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 50px 20px;
  box-sizing: border-box;
}
main.home .offset .offset-inner h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: #FFFFFF;
  text-align: center;
  margin: 0 auto 5px;
}
main.home .offset .offset-inner h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #FFFFFF;
  text-align: center;
  margin: 0 auto 30px;
}
main.home .offset .offset-inner h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0 0 5px;
  min-height: 48px;
}
main.home .offset .offset-inner h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin: 0 0 15px;
  color: #000;
}
main.home .offset .offset-inner h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
  color: #000;
}
main.home .offset .offset-inner label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
  color: #000;
}
main.home .offset .offset-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  color: #FFFFFF;
  text-align: center;
  max-width: 900px;
  width: 100%;
  margin: 20px auto;
}
main.home .offset .offset-inner a {
  display: block;
  padding: 15px 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: #FFFFFF;
  text-align: center;
  border-radius: 38px;
}
main.home .offset .offset-inner .plans {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-bottom: 30px;
}
main.home .offset .offset-inner .plans .plan {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
}
main.home .offset .offset-inner .plans .plan.yellow a {
  background: #DC933D;
}
main.home .offset .offset-inner .plans .plan.yellow h4 {
  color: #DC933D;
}
main.home .offset .offset-inner .plans .plan.green a {
  background: #2AAC37;
}
main.home .offset .offset-inner .plans .plan.green h4 {
  color: #2AAC37;
}
main.home .offset .offset-inner .plans .plan.red a {
  background: #E74141;
}
main.home .offset .offset-inner .plans .plan.red h4 {
  color: #E74141;
}
main.home .offset .offset-inner .consist {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 10px;
  align-items: center;
  background: #ECECEC;
  border-radius: 14px;
  overflow: hidden;
  padding-right: 10px;
  margin-bottom: 14px;
}

@media (max-width: 1080px) {
  main.home .banner .banner-bottom .banner-bottom-inner {
    grid-template-columns: 1fr 1fr;
  }
  main.home .ai-suggest .ai-suggest-inner .suggests {
    grid-template-columns: 1fr 1fr;
  }
  main.home .ai-suggest .ai-suggest-inner .cost {
    grid-template-columns: 1fr;
  }
  main.home .offset .offset-inner .plans {
    grid-template-columns: 1fr;
  }
  main.home .ai-suggest .ai-suggest-inner .btn {
    font-size: 24px;
    width: fit-content;
    padding: 25px 30px;
    margin: auto;
  }
}

@media (max-width: 1023px) {
  main.home .banner .banner-inner .details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  main.home .banner .banner-inner .details {
    grid-template-columns: 1fr;
  }
  main.home .banner .banner-bottom .banner-bottom-inner {
    grid-template-columns: 1fr;
  }
  main.home .whop .whop-inner .nature {
    display: block;
  }
  main.home .footplan .footplan-inner .plans {
    grid-template-columns: 1fr;
  }
  main.home .ai-suggest .ai-suggest-inner .suggests {
    grid-template-columns: 1fr;
  }
}