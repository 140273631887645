.not-found {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-image: url('https://d365pq86x330zn.cloudfront.net/11613851-c386-4110-1e61-c186710R1566.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.not-found h1 {
  font-size: 120px;
  color: #539B2E;
}