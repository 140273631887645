.privacy-policy {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #212121;
  padding: 0 20px;
}
.privacy-policy .privacy-policy-inner {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 120px 0;
  box-sizing: border-box;
}
.privacy-policy h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
  margin: 0;
  color: #FFFFFF;
}
.privacy-policy h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  margin: 0;
  color: #FFFFFF;
}
.privacy-policy p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  color: #FFFFFF;
}
.privacy-policy a {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  color: #FFFFFF;
  text-decoration: underline;
}
.privacy-policy .title-wrapper {
  background: #070707;
  padding: 20px;
}
.privacy-policy ol,
.privacy-policy ul {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  margin: 0;
  color: #FFFFFF;
}