footer.home-footer {
  padding: 50px 20px;
  background-color: #000;
  background-image: url('https://d365pq86x330zn.cloudfront.net/7676601e-ee66-473p-36e3-x8x773837006.png');
  background-position: center;
  background-size: cover;
}

footer.home-footer h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.3;
  color: #FFFFFF;
  margin-bottom: 30px;
}

footer.home-footer h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: #FFFFFF;
}

footer.home-footer h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #FFFFFF;
}

footer.home-footer p {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.3;
  color: #969696;
  margin-bottom: 5px;
}

footer.home-footer label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #FFFFFF;
}

footer.home-footer a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #FFFFFF;
}

footer.home-footer .footer-inner {
  max-width: 1024px;
  margin: auto;
}

footer.home-footer .footer-inner .get-in-touch {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 40px;
}
footer.home-footer .footer-inner .get-in-touch .get-in-touch-inner {
  background: #212121;
  border-radius: 20px;
  padding: 45px;
}
footer.home-footer .footer-inner .get-in-touch .img-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
footer.home-footer .footer-inner .get-in-touch .img-wrapper img {
  max-width: 120%;
  margin-bottom: -20%;
  float: right;
}
footer.home-footer .footer-inner .form-group {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
footer.home-footer .footer-inner .form-group label {
  flex: 0 1 auto;
  min-width: 100px;
  max-width: 100px;
}
footer.home-footer .footer-inner .form-group input:not([type="checkbox"]) {
  border: none;
  outline: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 430px;
  background: #FFFFFF;
  border-radius: 5px;
  margin: 10px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  padding: 15px 25px;
}
footer.home-footer .footer-inner .form-group.check-wrap {
  background: #303030;
  border-radius: 5px;
  padding: 15px 20px;
}
footer.home-footer .footer-inner .form-group.check-wrap label {
  flex: 1 1 auto;
  padding-left: 15px;
  font-size: 13px;
  max-width: 100%;
}
footer.home-footer .footer-inner .form-group.check-wrap .checkbox-wrapper {
  background: #FFFFFF;
  border: 1px solid #777777;
  border-radius: 5px;
}
footer.home-footer .footer-inner .form-group.check-wrap .checkbox-wrapper input {
  transform: scale(2);
}
footer.home-footer .footer-inner button {
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(180deg, #539B2E 0%, #74A524 100%);
  border-radius: 30px;
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #FFFFFF;
  padding: 20px;
  text-align: center;
}
footer.home-footer .footer-inner .profile {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
footer.home-footer .footer-inner .profile .details {
  padding: 0 20px;
}

footer.home-footer .footer-inner .bottom-part {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  footer.home-footer .footer-inner .get-in-touch {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
  footer.home-footer .footer-inner .get-in-touch .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer.home-footer .footer-inner .get-in-touch .img-wrapper img {
    max-width: 100%;
    margin-bottom: 0;
    float: unset;
  }
}
@media (max-width: 767px) {
  footer.home-footer .footer-inner .bottom-part {
    display: block;
  }
  footer.home-footer .footer-inner .bottom-part .company {
    margin-bottom: 20px;
  }
  footer.home-footer .footer-inner img {
    max-width: 100%;
  }
  footer.home-footer .footer-inner .form-group.one-line-mobile {
    display: block;
  }
}